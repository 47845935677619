import config from 'config'
export default {
    name: 'RetrievalDetail',

    mounted () {
        this.type = this.$route.query.type;
        this.code = this.$route.query.code;
        this.uploadList = this.$refs.upload.fileList;
        this.handleGetData();
    },

    methods: {
        handleGetData () {
            this.$Message.loading({content: '数据请求中...', duration: 0})
            let options = {archives_code: this.code};
            this.$api.archives.archivesDetail(options).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.item = res.obj;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 查看调档记录
        handleRetrieveLogList () {
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '数据请求中...', duration: 0})
            this.$api.archives.retrieveLogList({
                archive_id: this.item.archive_id,
                page: this.page,
                page_number: 20
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})

                if (res.list.length <= 0) return this.$Message.info({content: '暂无数据'})

                this.obj = res.obj;
                this.items = res.list;
                this.isModalType = 1
                this.isModal = true;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 获取档案修改记录
        handleGetModificationLogList () {
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '数据请求中...', duration: 0})
            this.$api.archives.archivesLogList({
                archives_code: this.item.archives_code,
                page: this.page,
                page_number: 20
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})

                if (res.list.length <= 0) return this.$Message.info({content: '暂无数据'})

                this.obj = res.obj;
                this.items = res.list;
                this.isModalType = 2
                this.isModal = true;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 修改档案文件列表
        fileListUpdate() {
            if(this.loading) return ;
            this.loading = true;
            this.$Message.loading({content: '提交中...',duration: 0})
            let data = {
                files: this.files_current_obj.map(v => {return {file_name: v.file_name, file_path: v.file_path,file_tag:v.tag_id==0?'':v.file_tag}}),
                category_id: this.item.cateogry_id,
                archive_id: this.item.archive_id
            }
            this.$api.archives.fileListUpdate(data).then(res=>{
                this.$Message.destroy()
                this.loading = false;
                this.isFiles = false;
                this.files_current_obj = [];
                this.folderCurrent = 0;
                this.folderList = [{tag_name:'全部',tag_id:0}]
                this.handleGetData()
            }).catch(err=>{
                this.loading = false;
                this.$Message.destroy()
            })
        },

        //关闭弹窗
        closeModal() {
            if(this.files_current_obj.length==0) return this.isFiles = false;
            this.$Modal.confirm({
                title: '提示',
                content: `上传的文件还没保存，确认关闭？`,
                onOk: () => {
                    this.isFiles = false
                }
            })
        },

        // 查看档案文件列表
        // 查看上传附件
        handleFileList (page) {
            if (this.loading) return;
            this.$Message.loading({content: '数据请求中...', duration: 0})
            this.loading = true;
            this.$api.archives.fileList({
                archive_id: this.item.archive_id,
                page_number: 20,
                page: page
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                // if (res.list.length <= 0) return this.$Message.warning({content: '暂无相关档案数据'})
                this.items = res.list;
                this.obj = res.obj;
                this.getFolderList();
                this.isFiles = true;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 获取标签列表
        getFolderList () {
            if(this.folderList.length>1) return
            this.$api.archives.getFolderList({ archive_id: this.item.archive_id}).then(res => {
                let arr = res.list;
                arr.forEach((v,i)=>{
                    arr[i].file_num = 0;
                    this.items.forEach(e=>{
                        if(v.tag_id == e.tag_id){
                            arr[i].file_num+=1
                        }
                    })
                })
                this.folderList =this.folderList.concat(arr)
            })
        },

        // 上传 - 成功
        handleOnSuccess (response, file, fileList) {
            let obj = response.list[0];
            this.folderList[this.folderCurrent].file_num++
            obj['file_tag'] = this.folderList[this.folderCurrent].tag_name;
            obj['tag_id'] = this.folderList[this.folderCurrent].tag_id;
            obj.size=  (file.size/(1024 * 1024)).toFixed(2) + "MB"
            this.$Message.success({content: obj.file_name + '上传成功'})

            this.files_current_obj.pop();
            this.files_current_obj.push(obj)
            this.uploadList=[];
            this.isDoing=false
        },

           // 上传 - 成功
           handleOnSuccess (response, file, fileList) {
            let obj = response.list[0];
            this.folderList[this.folderCurrent].file_num++
            obj['file_tag'] = this.folderList[this.folderCurrent].tag_name;
            obj['tag_id'] = this.folderList[this.folderCurrent].tag_id;
            obj.size=  (file.size/(1024 * 1024)).toFixed(2) + "MB"
            this.$Message.success({content: obj.file_name + '上传成功'})
            this.files_current_obj.pop();
            this.files_current_obj.push(obj)
            this.uploadList=[];
            this.isDoing=false

        },

        // 上传进度
        handleProgress(event, file, fileList){
           if(this.files_current_obj.length!=1){
            this.uploadList.push(file)
           }

            event.target.onprogress= function(e){
              let uploadPercent = parseFloat(((e.loaded / e.total) * 100).toFixed(2))	// 保留两位小数，具体根据自己需求做更改
                this.percent=uploadPercent;
                file.showProgress = true
                file.percentage = uploadPercent
              // 手动设置显示上传进度条 以及上传百分比

            }
        },

        // 上传 - 失败
        handleOnError (response, file, fileList) {
            let obj = response.list[0];
            this.$Message.success({content: obj.file_name + '上传失败'})
        },

        // 上传文件前
        beforeUpload(files){
            this.isDoing=true;
            if(this.folderList.length==0){
                this.$Message.warning({content:'请先创建目录..'})
                return false;
            }

            this.files_current_obj.push({
                download_url: "",
                file_name: files.name,
                file_path: "",
                file_tag: "",
                file_url: "",
                tag_id: 0,
                size:  (files.size/(1024 * 1024)).toFixed(2) + "MB"
            })
        },

        // 切换目录
        changeFolder (index,v) {
            this.folderCurrent=index;
        },

        // 删除标签接口
        handdleDeleteFolder (i,val) {
            let { id, t} = this.$route.query;
            if(this.loading) return
            this.$Modal.confirm({
                title: '删除',
                content: `将清空${val.tag_name}里面的所有文件，且不可恢复，确定？`,
                onOk: () => {
                    this.loading = true;
                    if(!id){//新增的标签
                        for (let index = 0,len=this.files_current_obj.length; index < len; index++) {
                            const e = this.files_current_obj[index];
                            if(e.tag_id==val.tag_id){
                                this.files_current_obj.splice(index,1)
                                index--;
                                len--;
                            }
                        }
                        this.folderCurrent =this.folderCurrent > 0?this.folderCurrent-1:0;
                        this.folderList.splice(i,1);
                        this.loading = false;
                        return
                    }
                    let data = {
                        archive_id:this.archive_id,
                        delete_tags:[val.tag_id]
                    };
                    this.$api.archives.deleteFolder(data).then(res=>{
                        this.loading = false;
                        this.$Message.destroy()
                        this.$Message.success({content: '删除成功'})
                        this.folderCurrent =this.folderCurrent > 0?this.folderCurrent-1:0;
                        this.folderList.splice(i,1);
                        this.handleGetData();
                        this.handleFileList();

                    }).catch(err=>{
                        this.loading = false;
                        this.$Message.destroy()
                        this.$Message.success({content: '删除失败'})
                    })
                }
            })
        },
        // 添加目录
        addFolder(){
            if(!this.folderName)return this.$Message.warning({content: '文件夹名称不能为空...'})
            if (this.folderList.map(v => v.tag_name).indexOf(this.folderName) >= 0) return this.$Message.warning({content: '文件夹已存在...'}); // 排除重名
            this.folderList.push({tag_name:this.folderName,tag_id:new Date().getTime(),file_num: 0})
            this.folderName = '';
            this.folderCurrent = this.folderList.length-1;
        },
        // 相关附件操作
        handleFilesAction (row, type, arr = 0,  index = 0) {
            switch (type) {
                case 'view':
                    window.open(row.file_url)
                    break;
                case 'download':
                    window.open(row.download_url)
                    break;
                    case 'delete':
                        if (this.loading) return;
                        this.$Modal.confirm({
                            title: '删除',
                            content: this.roleStaff['archive/file_recycle/recover']!=0? `确定删除附件${row.file_name}吗？删除后可在文件回收站中找回并按原始路径恢复！`:`删除附件${row.file_name}后不可恢复，确认?`,
                            onOk: () => {
                                // 未上传文件
                                if (arr == 0) {
                                    this.folderList[this.folderCurrent].file_num--
                                    this.files_current_obj.splice(index, 1)
                                }
                                // 已上传文件
                                if (arr == 1) {
                                    this.loading = true;
                                    this.$Message.loading({content: '删除中...', duration: 0})
                                    this.$api.archives.fileDel({
                                        archive_id: Number(this.item.archive_id),
                                        delete_files: [Number(row.file_id)]
                                    }).then(res => {
                                        this.loading = false
                                        this.$Message.destroy()
                                        this.folderList[this.folderCurrent].file_num--
                                        if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                                        this.$Message.success({content: '删除成功'})
                                        this.items.splice(index, 1)
                                        this.files--
                                    }).catch(err => {
                                        this.loading = false
                                        this.$Message.destroy()
                                        this.$Message.success({content: '删除失败'})
                                        console.log(err)
                                    })

                                }
                            }
                        })
                        break;
            }
        },
        handleType(row) {
            let arr = row.file_name?row.file_name.split('.'):[];
            if (['jpg', 'png', 'jpeg', 'gif'].indexOf(arr[arr.length - 1]) >= 0) return 'images'
            if (['rar', 'zip', 'cab', 'lzh', 'ace', '7-zip', 'tar', 'gzip', 'uue', 'bz2','jar','iso'].indexOf(arr[arr.length - 1]) >= 0) return 'zip'
            return 'file'
        },
    },

    data () {
        return {
            isDoing:false,
            uploadList: [],

            uploadData:{
                token: _global.token,
            },
            auths: _global.auths,
            publicPath: process.env.BASE_URL,
            config,
            loading: false,
            keyword: '',
            type: null,
            code: null,
            title: '',

            files_current_obj: [],//新上传的文件

            page: 1,
            obj: {},
            items: [],
            item: {},
            folderList:[{tag_name:'全部',tag_id:0}],//上传的目录文件夹
            folderCurrent: 0,
            isModal: false,
            isModalType: 1,
            isFiles: false,
            isEdit: false,//编辑文件状态
            folderName: '',//新增文件夹的名称
            roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),

        }
    }
}